<template>
  <v-btn class="ml-0" min-width="0" text to="/app/reference/contact" exact>
    <v-icon>mdi-phone-message-outline</v-icon>
  </v-btn>
</template>

<script>
export default {
  name: "DefaultGoContact",
};
</script>
